.main-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.content {
    display: flex;
    flex: 1;
    width: 100%;
}