.sidebar {
    display: grid;
    grid-template-rows: 1fr fit-content(1px);
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 4px rgb(0, 0, 0, 0.3);
    z-index: 1;
    min-width: 150px;
}

.sidebar span:hover {
    cursor: pointer;
    font-weight: bold;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}
  
.sidebar-element, .sidebar-settings {
    margin-left: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 10px;
    min-width: 120px;
}


.sidebar-icon {
    margin-right: 10px;
}

.sidebar-settings {
    margin-top: auto;
    margin-bottom: 10px;
}
  