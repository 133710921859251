.editor-js {
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    border-radius: 3px;
    width: 100%;
    padding: 10px 20px;
    margin: 0;
    height: fit-content;
    overflow: hidden;
}

.codex-editor__redactor {
    padding-bottom: auto !important;
}

.image-tool__image-picture {
    margin: auto;
}

.select-input {
    width: 100%;
}

.adventure-select {
    margin-left: 133px;
}

.bigger-icon {
    font-size: 17px;
}

.bigger-icon-2 {
    font-size: 19px;
}

.green-color {
    color: rgba(147, 196, 125, 0.8);
}

.day-section {
    position: relative;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 10px;
    width: 100%;
}

.day-add {
    margin-top: -30px;
    margin-bottom: -30px;
    margin-left: auto;
    margin-right: -20px;
}


.day-add-button {
    margin: 0px;
}

.day-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    left: auto;  /* Asegúrate de incluir esta línea */
    cursor: pointer;
}

.day-remove-icon {
    width: 10px;
}

.day-field {
    margin-top: 10px;
    margin-bottom: 10px;
}

.text-field input {
    margin-right: 32px;
    max-width: 285px;
}

.day-remove-icon {
    width: 100%;
    margin-left: auto;
}

.link-update {
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 10px 40px 15px 40px;
}

.link-update .inmutable-input {
    min-width: 300px;
}

.link-update div {
    display: flex;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
    min-width: 200px;
    align-items: center;
}

.link-update .update-div {
    max-width: 220px;
}

.link-update input {
    margin-left: auto;
    font-size: 12px;
}

.link-update span {
    font-size: 12px;
    margin-right: 6px;
    
}

.icon-span {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
}

.icon-span:hover {
    cursor: pointer;
}

.close-modal-span {
    position: absolute;
    top: 6px;
    right: 1px;
}

.close-modal-span:hover {
    cursor: pointer;
}

.modal-name {
    font-weight: bold;
}

.switch-group-main {
    width: 100%;
    align-items: left;
    justify-content: left;
}

.switch-group {
    display: flex;
    width: 150px;
    flex-direction: column; /* Organiza en filas */
    gap: 10px; /* Espacio entre los elementos */
    margin-bottom: 10px;
}

.switch-group div {
    text-align: right;
}


.instance-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    vertical-align: middle;
}

.modal-date {
    padding: 5px;
}

.form-element:hover {
    background-color: #d7d7d779;
}

.new-instance {
    background-color: rgba(106, 194, 66, 0.208);
}

.incorporation {
    background-color:  rgba(66, 147, 194, 0.208); /* #cce5ff; /* Light blue background */
}

.enrolled-false {
    background-color: rgba(255, 0, 0, 0.208);/* #fbc5c5; /* Light red background */
}

.changed-field {
    background-color: rgba(255, 255, 0, 0.208);
}

.white-bg {
    background-color: white;
}