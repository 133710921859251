.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 1rem;
    background-color: #fff;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    overflow-x: auto;
    min-height: 45px;
    z-index: 10;
}

.header-logo {
    height: 35px;
}

.header-logo:hover {
    cursor: pointer;
}

.header-user {
    display: flex;
    align-items: center;
}

.header-user span {
    margin-right: 12px;
    font-size: 13px;
}

.header-icon-span {
    min-width: 60px;
    text-align: right;
}

.header-icon {
    font-size: 18px;
    margin-right: 10px;
    margin-top: 1.75px;
    cursor: pointer;
}

.header-icon:hover {
    font-size: 19px;
}

.header-user img {
    height: 21px;
    margin-right: 10px;
    margin-top: 1.75px;
    cursor: pointer;
}

.popup {
    position: absolute;
    top: 50px;
    min-width: 70px;
    border-radius: 3px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-right: 30px;
    z-index: 1000;
}

.popup span {
    cursor: pointer;
}

.popup span:hover {
    font-weight: bold;
}