.settings-main {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
}

.settings-section {
    padding: 40px 0px 0px 60px;
    display: flex;
    flex-direction: column;
    width: 150px;
}

.settings-section span {
    padding: 3px;
    width: fit-content;
}

.settings-section-title {
    font-weight: bold;
    font-size: 16px;
}

.settings-section-option:hover {
    cursor: pointer;
}

.settings-section-option:hover {
    font-weight: bold;
}

.file-info {
    display: flex;
    align-items: center;
}

.file-name {
    margin-right: 10px;
}

.delete-file {
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 14px;
}

.delete-file:hover {
    cursor: pointer;
    color: darkred;
}
