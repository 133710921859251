body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.main-content {
    width: 100%;
    height: auto;
    position: relative;
    padding: 10px;
    overflow: auto;
}

.preview {
    overflow: visible !important;
    box-shadow: none !important;
}

.back-button {
    border-radius: 3px;
    border: none;
    padding: 6px 15px 6px 15px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    background-color: rgb(224, 224, 224);
    font-size: 12px;
    cursor: pointer;
}

.back-button:hover {
    background-color: rgb(196, 196, 196);
}

.page-name {
    padding-left: 20px;
    font-size: 14px;
    font-weight: bold;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 5px 40px 15px 40px;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-content button {
    margin: 5px 10px 5px 10px;
}

.delete-button {
    border-radius: 3px;
    border: none;
    padding: 6px 15px 6px 15px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    background-color: rgb(238, 128, 128);
    font-size: 12px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: rgb(231, 86, 86);
}

.accept-button {
    border-radius: 3px;
    border: none;
    padding: 6px 15px 6px 15px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    background-color: rgba(147, 196, 125, 0.8);
    font-size: 12px;
    cursor: pointer;
}

.accept-button:hover {
    background-color: rgba(130, 174, 110, 0.8);
}

input[type="date"],
textarea {
    font-family:  Arial, Helvetica, sans-serif;
}

.create-button {
    border-radius: 3px;
    border: none;
    padding: 6px 15px 6px 15px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    background-color: rgba(147, 196, 125, 0.8);
    font-size: 12px;
    cursor: pointer;
}

.create-button:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

.create-button:hover {
    background-color: rgba(130, 174, 110, 0.8);

}

.simple-button {
    border-radius: 3px;
    margin: none;
    border: none;
    padding: 6px 15px 6px 15px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    background-color: rgb(224, 224, 224);
    font-size: 12px;
    cursor: pointer;
}

.simple-button:hover {
    background-color: rgb(196, 196, 196);

}

/* Table styling */

.table-main {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.table-elements {
    position: relative;
    max-height: calc(100vh - 120px);

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: #dddddd;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.table-elements::-webkit-scrollbar {
    height: 8px; /* Ajusta la altura de la barra de desplazamiento horizontal */
}

.table-head-options {
    margin-bottom: 7.5px;
    display: flex;
    justify-content: flex-end;
}

.table-head-options .back-button {
    margin-right: auto;
}

.table-head-filters {
    margin-right: auto;
    display: flex;
    justify-content: flex-end;
}

.table-head-search {
    font-size: 12px;
    border-radius: 3px;
    border: none;
    padding-left: 8px;
    padding-right: 8px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
}

.table-head-select {
    font-size: 12px;
    border-radius: 3px;
    border: none;
    padding-left: 8px;
    padding-right: 8px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    margin-left: 10px;
}

.language-selector {
    max-width: 50px;
}


.table-elements {
    display: flex;
    overflow: auto;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    border-radius: 3px;
}

.table-elements table {
    table-layout: auto;
    min-width: 100%;
    text-align: left;
}

.table-elements thead {
    top: 0;
    background-color: white;
    z-index: 1;
    text-align: left;
}

.table-elements hr {
    border: none;
    height: 0.125px;
    background-color: rgb(181, 181, 181);
    padding: 0px;
    margin: 0px;
}

.table-elements td {
    vertical-align: middle;
}

.table-elements th {
    white-space: nowrap;
    padding: 10px 10px 0px 10px; 
}
.table-elements td {
    padding: 10px 10px 10px 10px;
}

.table-elements span {
    font-size: 16px;
}

.table-elements .clickable-icons {
    display: inline-flex;
    vertical-align: middle;
}

.table-elements .clickable-span {
    display: inline-flex;
    align-items: center;
    margin: 0px 2px 0px 2px;
}

.table-elements .clickable-span:hover {
    cursor: pointer;
}

.table-elements .unclickable-span {
    display: inline-flex;
    align-items: center;
    margin: 0px 2px 0px 2px;
    color: #888;
}

.table-elements .td-right {
    text-align: right;
}

.table-elements .switch {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    width: 29px;
    height: 17px;
    margin-left: 10px;
    margin-top: 1px;
}
  
.table-elements .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.table-elements .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(238, 128, 128);
    transition: .4s;
    border-radius: 20px;
}
  
.table-elements .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 2.75px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}
  
.table-elements input:checked + .slider {
    background-color: rgba(147, 196, 125, 0.8);
}
  
.table-elements input:checked + .slider:before {
    transform: translateX(11px);
}

.no-forms {
    width: 100%;
    text-align: center;
}

.no-forms div {
    width: fit-content;
    margin: auto;
    padding: 10px;
    color: gray;
}

/* Form styling */

.form-container {
    width: 100%;
    max-height: calc(100vh - 120px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: #dddddd;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.form-content {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-content form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 15px;
}

.form-content .small-form {
    max-width: 350px;
}

.form-content form .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.form-content form .field-name {
    flex: 1;
    min-width: 120px;
    margin-right: 1rem;
    text-align: right;
}

.form-content form .textarea-field-name {
    flex: 1;
    min-width: 120px;
    margin-right: 1rem;
    text-align: right;
    margin-bottom: auto;
    margin-top: 4px;
}

.form-content form .field-read-only {
    background-color: rgb(235, 234, 234);
}

.form-content form input,
.form-content form textarea,
.form-content form select {
    flex: 2;
    font-size: 12px;
    border-radius: 3px;
    border: none;
    padding: 4px 6px 4px 6px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
}

.form-content form input[type="file"] {
    display:None
}

.form-content form .file-input {
    flex: 2;
}

.form-content form .file-input-span {
    margin-left: 10px;
    width: 150px;
    cursor: default;
}

.form-content form .file-input-span.disabled {
    color: rgb(84, 84, 84)
}

.form-content form .file-input label {
    border-radius: 3px;
    border: none;
    padding: 6px 15px 6px 15px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 0.3);
    background-color: rgb(224, 224, 224);
    font-size: 12px;
    cursor: pointer;
}

.form-content form .file-input label:hover {
    background-color: rgb(196, 196, 196);
}

.form-content form .file-input label.disabled,
.form-content form .file-input label.disabled {
    background-color: rgba(224, 224, 224, 0.466);
    color: rgb(84, 84, 84);
    cursor: default;
}

.form-content form .file-input label.disabled:hover,
.form-content form .file-input label.disabled:hover {
    background-color: rgb(224, 224, 224, 0.466);
    color: rgb(84, 84, 84);
}

.form-content form .switch {
    flex: 2;
    position: relative;
    height: 8px;
    flex: 2;
    font-size: 12px;
    border-radius: 3px;
    border: none;
    padding: 4px 6px 4px 6px;
}
  
.form-content form .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.form-content form .slider {
    position: absolute;
    cursor: pointer;
    vertical-align: middle;
    max-width: 29px;
    height: 17px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(238, 128, 128);
    transition: .4s;
    border-radius: 20px;
}
  
.form-content form .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 2.75px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}
  
.form-content form input:checked + .slider {
    background-color: rgba(147, 196, 125, 0.8);
}

.form-content form .slider.disabled,
.form-content form .slider.disabled {
    background-color: rgb(238, 128, 128, 0.5);
    cursor: default;
}

.form-content form input:checked + .slider.disabled,
.form-content form input:checked + .slider.disabled {
    background-color: rgba(147, 196, 125, 0.5);
    cursor: default;
}
  
.form-content form input:checked + .slider:before {
    transform: translateX(11px);
}


.form-content form textarea {
    resize: none;
    min-height: 60px;
}

.form-content form .create-item-span {
    vertical-align: middle;
    font-size:15px;
    color: rgba(147, 196, 125, 0.8);
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
}

.form-content form .create-item-span:hover {
    cursor: pointer;
}

.form-content form .create-item-span.disabled,
.form-content form .create-item-span.disabled {
    color: rgba(84, 84, 84, 0.4);
    cursor: default;
}

.form-content form .create-item-span.disabled:hover,
.form-content form .create-item-span.disabled:hover {
    color: rgba(84, 84, 84, 0.4);
    cursor: default;
}


.form-content form .delete-item-span {
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    color: rgb(238, 128, 128);
}

.form-content form .delete-item-span:hover {
    cursor: pointer;
}

.form-content form .delete-item-span.disabled,
.form-content form .delete-item-span.disabled {
    color: rgba(84, 84, 84, 0.4);
    cursor: default;
}

.form-content form .delete-item-span.disabled:hover,
.form-content form .delete-item-span.disabled:hover {
    color: rgba(84, 84, 84, 0.4);
    cursor: default;
}

.form-content form .item-list-container {
    width: 100%;
}

.form-content form .input-with-icon {
    flex:2;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.form-content form .item-list {
    float: right;
    margin: 0px 10px 0px 0px;
}

.form-content form .item-with-icon {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-content form .item-with-icon .action-span.disabled {
    color: rgb(84, 84, 84);
    cursor: default;
}

.form-content form .item-with-icon .action-span-limited {
    max-width: 220px;
}


.form-content form .item-with-icon .delete-item-span {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.form-content form .form-section-span {
    font-size: 14px;
    font-weight: bold;
}

.form-content form .not-first {
    margin-top: 25px;
}

.form-content form button {
    align-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
