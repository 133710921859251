.auth-form-container {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-form {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, .1);
    
    border-radius: 10px;
}

.auth-form-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-logo {
    height: 60px;
    margin-top: 15px;
    margin-bottom: 40px;
}

.auth-logo-f {
    height: 60px;
    margin-top: 15px;
}

.auth-form-username {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.auth-form-username .username-input {
    width: 250px;
    border-radius: 5px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.auth-form-password {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.auth-form-password .password-input {
    width: 250px;
    border-radius: 5px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.auth-form-username-f {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.auth-form-username-f .username-input {
    width: 250px;
    border-radius: 5px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.info-f {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 250px;
}

.auth-form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.auth-button {
    border-radius: 5px;
    border: none;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background-color: rgba(235,100,34, 0.8);
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
}

.auth-button:hover {
    background-color: rgba(194, 86, 31, 0.8);
}

.auth-form-forgot {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.auth-form-forgot span{
    font-size: 10px;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
}

.auth-error {
    display: block;
    text-align: center;
    color: rgb(235, 45, 45);
}

