.popup-container {
    display: flex;
    position: fixed;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.success-popup {
    background-color: rgba(147, 196, 125);
    border-radius: 3px;
    padding: 20px;
    width: auto;
    position: relative;
}

.message-popup {
    background-color: rgb(224, 224, 224);
    border-radius: 3px;
    padding: 20px;
    width: auto;
    position: relative;
}

.warning-popup {
    background-color: rgb(160, 208, 252);
    border-radius: 3px;
    padding: 20px;
    width: auto;
    position: relative;
}

.error-popup {
    background-color: rgb(238, 128, 128);
    border-radius: 3px;
    color: white;
    padding: 20px;
    width: auto;
    position: relative;
}

.popup-container p {
    margin: 0;
    padding: 2px 15px 2px 15px;
}

.close-alert-span {
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 16px;
    cursor: pointer;
}
